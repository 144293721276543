.headerWhite {
    color: white;
    justify-content: center;
    text-align: center;
    padding-bottom: 80px;

    background-image: url("../images/headerUnderline.png");
    background-position: center;
    background-repeat: no-repeat;
}
.headerWhite h2 {
    font-size: 2.5em;
}
.textWhite {
    color: white;
    justify-content: center;
    align-items: center;
    margin-top: 4;

}
.textWhite h3 {
    font-size: 1.8em;
}
.textWhite p {
    font-weight: 600;
    font-size: 1em;
}
/*-------------------------------*/
.headerBlack {
    color: black;
    justify-content: center;
    text-align: center;
    padding-bottom: 80px;

    background-image: url("../images/headerUnderline.png");
    background-position: center;
    background-repeat: no-repeat;
}
.headerBlack h2 {
    font-size: 2.5em;
}
.textBlack {
    color: black;
    justify-content: center;
    align-items: center;
    margin-top: 4;
}
.textBlack h3 {
    font-size: 1.8em;
}
.textBlack p {
    font-weight: 600;
    font-size: 1em;
}
/*---------------------------------------------- section: home -------------------------*/
.mainSlide {
    color: white;
    text-align: center;
    background-image: url('../images/mainSlideBackground.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    background-color: rgba(20, 20, 24, 0.25);
    background-blend-mode: multiply;
    width: max;
    height: auto;
}
.mainSlide h1 {
    font-size: 4em;
}
.mainSlide p {
    font-size: 2em;
}
/*--------------------------------------------------------------------------------------*/
/*---------------------------------------------- section: about-us ---------------------*/
.aboutBackImg {
    background-image: url("../images/aboutSlideBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    width: 100%;
    padding: 30px 0px;
}
/*--------------------------------------------------------------------------------------*/
/*---------------------------------------------- section: sell -------------------------*/
.buyBackImg {
    background-image: url("../images/sellBackground.png");
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;

    background-color: rgba(20, 20, 24, 0.6);
    background-blend-mode: multiply;

    width: 100%;
    padding: 0;   
}

.buyBullets {
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
.buyBullets ul {
    list-style: none;
    color: white;
    margin-bottom: 80px;
    padding: 0 0 20px 0;
}
.buyBullets li {
    line-height: 30px;
    font-size: 24px;
    padding-bottom: 10px;
}
/*--------------------------------------------------------------------------------------*/
/*---------------------------------------------- section: consignment ------------------*/
.consignBackImg {
    background-image: url("../images/consignBackground.jpeg");
    background-size: cover;
    background-position: right;
    background-repeat:no-repeat;

    background-color: rgba(143, 137, 137, 0.1);
    background-blend-mode: multiply;

    width: 100%;
    padding: 30px 0;
}
/*--------------------------------------------------------------------------------------*/
/*---------------------------------------------- section: custom-pieces ----------------*/
.customBackImg {
    background-image: url("../images/customBackground.jpeg");
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;

    background-color: rgba(20, 20, 24, 0.6);
    background-blend-mode: multiply;

    width: 100%;
    padding: 30px 0;
}
/*--------------------------------------------------------------------------------------*/
/*---------------------------------------------- section: repairs ----- -----------------*/
.repairsBackImg {
    background-image: url("../images/repairsBackground.jpeg");
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;

    background-color: rgba(143, 137, 137, 0.1);
    background-blend-mode: multiply;

    width: 100%;
    padding: 30px 0;
}
/*--------------------------------------------------------------------------------------*/
/*---------------------------------------------- section: appraisals -------------------*/
.apprBackImg {
    background-image: url("../images/appraisalBackground.jpeg");
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;

    background-color: rgba(20, 20, 24, 0.6);
    background-blend-mode: multiply;

    text-align: center;
    width: 100%;
    padding: 30px 0;
}
.apprDropdownBtn {
    font-size: larger;
    color: white;
    padding: 0.5em;
    cursor: pointer;
}

/*--------------------------------------------------------------------------------------*/
/*---------------------------------------------- section: contact-us -------------------*/
.contactBackImg {
    background-image: url("../images/contactBackground.jpeg");
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    width: 100%;
    padding: 30px 0;

    background-color:rgba(20, 20, 24, 0.6);
    background-blend-mode: multiply;
}
/*--------------------------------------------------------------------------------------*/