.nav {
    background-color: #141418;
    color: white;
    padding: 0;
    text-align: center;
    height: auto;
    width: 100%;
    position: sticky;
    top: 0px; 
}
.navLogo {
    width: 250px;
    float: left;
}
.topnav {
    margin: 0px auto;
    margin-left: auto;
    text-align: right;
    overflow: hidden;
}

.navlink {
    float: right;
    display: block;
    color: #d79a2a;
    text-align: center;
    padding: 2rem 1rem;
    text-decoration: none;
    font-size: 1.2em;
}
.navlink:hover {
    background-color: #d79a2a;
    height: 100%;
    color: black;
}
