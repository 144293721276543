*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
    color: black;
}

section {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*---------------------------------------------- section: main -------------------------*/


/*---------------------------------------------- section: sell -------------------------*/
/*---------------------------------------------- section: consignment ------------------*/
/*---------------------------------------------- section: custom-pieces ----------------*/
/*---------------------------------------------- section: repairs ----------------------*/
/*---------------------------------------------- section: contact-us -------------------*/
